'use strict';

jQuery(document).ready(function () {
	var siteLogo = jQuery('.logo');

	jQuery('.site-panel').hover(over, out);

	function over(panel) {
		var hoveredElement = jQuery(panel.currentTarget);
		var hoveredElementClass = jQuery(hoveredElement).attr('class');
		var panelWrapper = jQuery('.panel-wrapper');

		if (hoveredElement.hasClass('site-panel-zakelijk')) {
			// Remove class from other panel
			var otherPanel = jQuery('.site-panel-consument');

			panelWrapper.removeClass('consument-panel-active');
			otherPanel.removeClass('panel-popout');
			otherPanel.addClass('panel-not-popout');

			jQuery(this).addClass('panel-popout');
			jQuery(panelWrapper).addClass('zakelijk-panel-active');
		}

		if (hoveredElement.hasClass('site-panel-consument')) {
			// Remove class from other panel
			var otherPanel = jQuery('.site-panel-zakelijk');

			panelWrapper.removeClass('zakelijk-panel-active');
			otherPanel.removeClass('panel-popout');
			otherPanel.addClass('panel-not-popout');

			jQuery(this).removeClass('panel-not-popout');
			jQuery(this).addClass('panel-popout');
			jQuery(panelWrapper).addClass('consument-panel-active');
		}
	}

	function out(panel) {}
});